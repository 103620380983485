import { FormWrapper } from "./FormWrapper";
import { Link } from "react-router-dom";
import { ROUTE_TERMS_OF_USE } from "../../routes/routes";
import { FormInput } from "../basic/FormComponents/FormInput";
import { Button } from "../basic/Button";
import { navigateHelper } from "../../utils/utils";

export const OrderFormStepOne = ({
  changeTab,
  formData,
  handleChange,
  errors,
}) => {
  return (
    <FormWrapper size={"md"} title={`Content Removal Request Form`}>
      <p className={`form-wrapper-subtitle subtitle lg`}>
        Providing this information authorizes Fortified-Web to generate a Letter
        of Authorization, which will be sent to service providers. Details can
        be found in our{" "}
        <Link onClick={() => navigateHelper()} to={ROUTE_TERMS_OF_USE}>
          Terms of Use
        </Link>
      </p>
      <form className={`form`} id={`contact-form-step-one`} action={undefined}>
        <div className={`form-cols`}>
          <FormInput
            required
            name={`firstName`}
            placeholder={`Enter your first name `}
            type={`text`}
            label={`First name`}
            inputValue={formData.firstName}
            onChange={handleChange}
            isError={!!errors.firstName}
            error={errors.firstName}
          />
          <FormInput
            required
            name={`lastName`}
            placeholder={`Enter your last name`}
            type={`text`}
            label={`Last name`}
            inputValue={formData.lastName}
            onChange={handleChange}
            isError={!!errors.lastName}
            error={errors.lastName}
          />
        </div>
        <FormInput
          required
          name={`organization`}
          placeholder={`Enter organization/company name`}
          type={`text`}
          label={`Organization/Company Name`}
          inputValue={formData.organization}
          onChange={handleChange}
          isError={!!errors.organization}
          error={errors.organization}
        />
        <FormInput
          name={`position`}
          placeholder={`Enter your position`}
          type={`text`}
          label={`Position`}
          inputValue={formData.position}
          onChange={handleChange}
          isError={!!errors.position}
          error={errors.position}
        />
        <FormInput
          required
          name={`email`}
          placeholder={`Enter your email address`}
          type={`email`}
          label={`Email Address`}
          inputValue={formData.email}
          onChange={handleChange}
          isError={!!errors.email}
          error={errors.email}
        />
        <FormInput
          name={`phone`}
          placeholder={`Enter your phone number`}
          type={`phone`}
          label={`Phone Number`}
          inputValue={formData.phone}
          onChange={handleChange}
          isError={!!errors.phone}
          error={errors.phone}
        />
        <FormInput
          name={`businessDescription`}
          placeholder={`Enter your business description (e.g., industry, key details)`}
          type={`text`}
          label={`Business Description`}
          inputValue={formData.businessDescription}
          onChange={handleChange}
          isError={!!errors.businessDescription}
          error={errors.businessDescription}
        />
        <Button
          type={"button"}
          onClick={() => changeTab(2)}
          size={"lg"}
          btnStyle={"primary"}
        >
          Next Step
        </Button>
      </form>
    </FormWrapper>
  );
};
