import { useEffect, useRef, useState } from "react";
import "./FormSelect.css";
import { chevron } from "../../../static/images";

export const FormSelect = ({
  required,
  disabled,
  label,
  placeholder,
  inputValue,
  onChange,
  error,
  isValid,
  isError,
  options,
}) => {
  const [selectValue, setSelectValue] = useState(inputValue);
  const [isOpen, setIsOpen] = useState(false);
  const [focusedOptionIndex, setFocusedOptionIndex] = useState(-1);
  const [lastKeyPressed, setLastKeyPressed] = useState("");
  const [keyPressCount, setKeyPressCount] = useState(0);
  const selectRef = useRef(null);
  const optionsRef = useRef([]);

  const handleToggleDropdown = () => {
    if (!disabled) {
      setIsOpen(!isOpen);
    }
  };

  const handleSelectOption = (value) => {
    setSelectValue(value);
    setIsOpen(false);
    if (onChange) {
      onChange(value);
    }
  };

  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const handleKeyDown = (event) => {
    if (isOpen) {
      const currentKey = event.key.toLowerCase();
      if (currentKey === lastKeyPressed) {
        setKeyPressCount(keyPressCount + 1);
      } else {
        setKeyPressCount(1);
      }
      setLastKeyPressed(currentKey);

      switch (event.key) {
        case "ArrowDown":
          event.preventDefault();
          setFocusedOptionIndex((prevIndex) =>
            Math.min(prevIndex + 1, options.length - 1),
          );
          break;
        case "ArrowUp":
          event.preventDefault();
          setFocusedOptionIndex((prevIndex) => Math.max(prevIndex - 1, 0));
          break;
        case "Enter":
          if (focusedOptionIndex >= 0 && focusedOptionIndex < options.length) {
            handleSelectOption(options[focusedOptionIndex]);
          }
          break;
        case "Escape":
          setIsOpen(false);
          break;
        default:
          const filteredOptions = options.filter((option) =>
            option.toLowerCase().startsWith(currentKey),
          );
          if (filteredOptions.length > 0) {
            const nextIndex = keyPressCount % filteredOptions.length;
            const option = filteredOptions[nextIndex];
            const index = options.indexOf(option);
            setFocusedOptionIndex(index);
          }
          break;
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
    }
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen, focusedOptionIndex, options, lastKeyPressed, keyPressCount]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (focusedOptionIndex >= 0 && optionsRef.current[focusedOptionIndex]) {
      optionsRef.current[focusedOptionIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [focusedOptionIndex]);

  return (
    <div className={`form-field`}>
      {label && (
        <p className={`form-label ${required ? "required" : ""}`}>{label}</p>
      )}
      <div
        ref={selectRef}
        className={`form-select-wrapper ${disabled ? "disabled" : ""} ${isValid ? "valid" : ""} ${isError ? "error" : ""} ${isOpen ? "open" : ""}`}
        onClick={handleToggleDropdown}
      >
        <div className="form-select-display">
          {selectValue
            ? options.find((option) => option === selectValue)
            : placeholder}{" "}
          <img className={`form-select-arrow`} src={chevron} alt="chevron" />
        </div>
        <div className={`form-select-dropdown`}>
          {options.map((option, index) => (
            <div
              key={index}
              ref={(el) => (optionsRef.current[index] = el)}
              className={`form-select-option subtitle md ${option === selectValue ? "selected" : ""} ${index === focusedOptionIndex ? "focused" : ""}`}
              onClick={() => handleSelectOption(option)}
            >
              {option}
            </div>
          ))}
        </div>
      </div>
      {isError && <p className={`error-message`}>{error}</p>}
    </div>
  );
};
