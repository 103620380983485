import { Link } from "react-router-dom";
import { ROUTE_TERMS_OF_USE } from "../../routes/routes";
import { FormWrapper } from "./FormWrapper";
import { Button } from "../basic/Button";
import { arrowRight } from "../../static/images";
import { FormInput } from "../basic/FormComponents/FormInput";
import { FormSelect } from "../basic/FormComponents/FormSelect";
import { FormCustombox } from "../basic/FormComponents/FormCustombox";
import { FormFileInput } from "../basic/FormComponents/FormFileInput";
import { FormTextarea } from "../basic/FormComponents/FormTextarea";
import { navigateHelper } from "../../utils/utils";

export const OrderFormStepTwo = ({
  changeTab,
  formData,
  handleChange,
  handleSelectChange,
  errors,
  onFileChange,
}) => {
  const countries = [
    "Afghanistan",
    "Åland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua & Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia & Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "British Virgin Islands",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Caribbean Netherlands",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo - Brazzaville",
    "Congo - Kinshasa",
    "Cook Islands",
    "Costa Rica",
    "Côte d’Ivoire",
    "Croatia",
    "Cuba",
    "Curaçao",
    "Cyprus",
    "Czechia",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Eswatini",
    "Ethiopia",
    "Falkland Islands",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard & McDonald Islands",
    "Honduras",
    "Hong Kong SAR China",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Kuwait",
    "Kyrgyzstan",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao SAR China",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar (Burma)",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "North Korea",
    "North Macedonia",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestinian Territories",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn Islands",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Réunion",
    "Romania",
    "Russia",
    "Rwanda",
    "Samoa",
    "San Marino",
    "São Tomé & Príncipe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Sint Maarten",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia & South Sandwich Islands",
    "South Korea",
    "South Sudan",
    "Spain",
    "Sri Lanka",
    "St. Barthélemy",
    "St. Helena",
    "St. Kitts & Nevis",
    "St. Lucia",
    "St. Martin",
    "St. Pierre & Miquelon",
    "St. Vincent & Grenadines",
    "Sudan",
    "Suriname",
    "Svalbard & Jan Mayen",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor-Leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad & Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks & Caicos Islands",
    "Tuvalu",
    "U.S. Outlying Islands",
    "U.S. Virgin Islands",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Vatican City",
    "Venezuela",
    "Vietnam",
    "Wallis & Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  return (
    <FormWrapper size={"md"} title={`Content Removal Request Form`}>
      <p className={`form-wrapper-subtitle subtitle lg`}>
        Providing this information authorizes Fortified-Web to generate a Letter
        of Authorization, which will be sent to service providers. Details can
        be found in our{" "}
        <Link onClick={() => navigateHelper()} to={ROUTE_TERMS_OF_USE}>
          Terms of Use
        </Link>
      </p>
      <form className={`form`} id={`contact-form-step-two`} action={undefined}>
        <FormInput
          name={`linkToRemove`}
          placeholder={`Enter link to the content you wish to remove`}
          type={`text`}
          label={`Link to the content you wish to remove`}
          onChange={handleChange}
          inputValue={formData.linkToRemove}
          isError={!!errors.linkToRemove}
          error={errors.linkToRemove}
        />
        <FormInput
          name={`linkToOriginal`}
          placeholder={`Enter link to original/official online presence`}
          type={`text`}
          label={`Link to original/official online presence (e.g., Website, Social Account)`}
          onChange={handleChange}
          inputValue={formData.linkToOriginal}
          isError={!!errors.linkToOriginal}
          error={errors.linkToOriginal}
        />
        <div className={`form-field`}>
          <p className={`form-label`}>
            Is the Content Protected by Trademark or Copyright?
          </p>
          <div className={`form-checkboxes-wrapper`}>
            <FormCustombox
              label={`Yes`}
              name={`isProtected`}
              id={`radio1`}
              type={`radio`}
              inputValue={formData.isProtected}
              onChange={handleChange}
              isError={!!errors.isProtected}
              error={errors.isProtected}
            />
            <FormCustombox
              label={`No`}
              name={`isProtected`}
              id={`radio2`}
              type={`radio`}
              inputValue={formData.isProtected}
              onChange={handleChange}
              isError={!!errors.isProtected}
              error={errors.isProtected}
            />
          </div>
        </div>
        <div
          className={`form-field ${formData.isProtected === "yes" ? "visible" : "hidden"}`}
        >
          <p className={`form-label`}>
            If Yes, Upload Copy of Certificate or Provide Registration Number
            and Jurisdiction
          </p>
          <div className={`form-cols`}>
            <FormInput
              name={`regNumber`}
              placeholder={`Enter your registration number`}
              type={`text`}
              onChange={handleChange}
              inputValue={formData.regNumber}
              isError={!!errors.regNumber}
              error={errors.regNumber}
            />
            <FormSelect
              inputValue={formData.jurisdiction}
              onChange={(value) => handleSelectChange("jurisdiction", value)}
              isError={!!errors.jurisdiction}
              error={errors.jurisdiction}
              options={countries}
              placeholder={"Select jurisdiction"}
            />
          </div>
        </div>
        <FormFileInput onFileSelect={onFileChange} formData={formData} />
        <FormTextarea
          name={`issue`}
          placeholder={`Explain your issue here...`}
          label={`Explain the Issue with as Many Details as Possible`}
          onChange={handleChange}
          inputValue={formData.issue}
          isError={!!errors.issue}
          error={errors.issue}
        />
        <div className={`form-buttons-wrapper`}>
          <Button
            onClick={() => changeTab(1)}
            type={"button"}
            size={"md"}
            btnStyle={"secondary"}
          >
            <img
              style={{ transform: `rotate(180deg)` }}
              src={arrowRight}
              alt="arrow right"
            />{" "}
            Back
          </Button>
          <Button
            onClick={() => changeTab(3)}
            type={"button"}
            size={"lg"}
            btnStyle={"primary"}
          >
            Next Step
          </Button>
        </div>
      </form>
    </FormWrapper>
  );
};
