import {
  ROUTE_MAIN,
  ROUTE_PRIVACY_POLICY,
  ROUTE_TERMS_OF_USE,
} from "../../routes/routes";
import { Link } from "react-router-dom";
import {
  facebook,
  footerLogo,
  linkedin,
  twitter,
  youtube,
} from "../../static/images";
import "./Footer.css";
import { navigateHelper } from "../../utils/utils";

export const Footer = () => {
  return (
    <footer className={`footer`}>

        <div className={`footer-copyright`}>
          <Link onClick={() => navigateHelper()} to={ROUTE_MAIN}>
            <img loading={`lazy`} src={footerLogo} alt="footer logo" />
          </Link>
          <p>Fortified-Web © 2024</p>
          <div className={`footer-copyright-links`}>
            <Link onClick={() => navigateHelper()} to={ROUTE_PRIVACY_POLICY}>
              Privacy Policy
            </Link>
            <Link onClick={() => navigateHelper()} to={ROUTE_TERMS_OF_USE}>
              Terms of Use
            </Link>
          </div>
      </div>
    </footer>
  );
};
